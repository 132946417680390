<template>
  <el-container class="post-onboarding flex-column ai-c jc-c">
    <div class="post-onboarding__body flex-column gap-2">
      <section class="flex-column gap-2">
        <Icon iconName="iknowa-logo-v4.svg" iconHeight="36px" iconWidth="198px"/>
        <div class="flex-column">
          <Typography variant="h4">
           Awesome, What’s Up Next, {{ firstName }} 👇
          </Typography>
          <div class="flex-column gap-half">
            <Typography variant="p" textWeight="500" :textColor="grayColor">
              Now you’ve got your Iknowa Passport, choose your next steps.
            </Typography>
          </div>
        </div>
      </section>
      <section class="post-onboarding__content flex-column jc-sb">
        <div class="flex-column">
          <SelectionCard
            @click="selectCard(step.value)"
            :hasTag="true"
            v-for="(step, index) in nextStep"
            :key="index"
            :data="step"
            :selectedValue="selectedRole">
            <template #tag>
              <Tag
                :tag="step.tag"
                :tagLabel="step.tagLabel"
                :dataValue="step.value"
                :selectedValue="selectedRole"/>
            </template>
          </SelectionCard>
        </div>
      </section>
    </div>
  </el-container>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SelectionCard from '@/core/components/common/cards/SelectionCard.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Tag from '@/core/components/ui/Tag.vue';
import Typography from '@/core/components/ui/Typography.vue';
import StringHelper from '@/core/helpers/string.helper';
import { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    Icon,
    SelectionCard,
    Typography,
    Tag
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    grayColor() {
      return 'rgba(12, 15, 74, 0.50)';
    },

    accentColor() {
      return '#FAA500';
    },

    firstName() {
      return this.user?.firstName.charAt(0).toUpperCase() + this.user?.firstName.slice(1);
    }
  },

  data() {
    return {
      nextStep: [
        {
          value: 1,
          title: 'Bid On A New Project',
          label: 'Create Your Company / Sole Trader Profile & Win New Work',
          icon: 'icon-house.svg',
          tag: '1,414',
          tagLabel: 'Live Projects'
        },
        {
          value: 2,
          title: 'Explore Iknowa',
          label: 'Refine Your Iknowa Passport & Get To Know The Dashboard',
          icon: 'icon-tp-avatar.svg',
          tag: 'FREE',
          tagLabel: 'Iknowa Passport'
        },
        {
          value: 3,
          title: 'Earn Up-Skill Certifications',
          label: 'Get A New Accreditation In Solar, EV or HeatPumps',
          icon: 'icon-tp-avatar.svg',
          tag: '32',
          tagLabel: 'Accredited Opportunities'
        },
      ],

      selectedRole: null,
      isSubmitting: false,
      StringHelper,

      isLoadingProjects: false,
      DASHBOARD_ROUTE
    };
  },

  created() {
    this.setNotInMainLayout(true);
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'updateUser',
      'saveUserState',
      'getUser',
      'setNotInMainLayout'
    ]),

    ...mapActions(PROJECTS_STORE, ['getProjectPageList']),

    selectCard(value) {
      this.selectedRole = value;
      this.setNotInMainLayout(false);
      window.location.href = `/${DASHBOARD_ROUTE}`;
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;
.post-onboarding {
  width: 100%;
  height: 100%;
  background: transparent;
  overflow-y: auto;

  &__body {
    max-width: 683px;
    min-height: 600px;
  }

  &__content {
    height: 100%;
  }
}

@include media-sm-max-width() {
  .post-onboarding {
    padding: 1rem 1.5rem;
    justify-content: flex-start;

    &__body {
      min-height: unset;
      height: 100%;
    }
  }
}

@media screen and (max-width: 382px) {
  .post-onboarding {
    &__wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
